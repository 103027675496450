.card-header img{
  height: 40px;
}

.mb-3 .btn {
  background-color: $info;
  border-color: $info;
}
.mb-3 .btn:hover {
  background-color: $info-light;
  border-color: $info-light;
}

.bg-dark {
  background-color: $info!important;
}

.page-item.active .page-link {
  background-color: $info!important;
  border-color: $info!important;
}

.form-field{
    margin-bottom: 10px;
    padding-right: 10px;
}

.icon-orange {
  color: $info
}